.ir {position:absolute; left:-9999em; top:-9999em; font-size:9px; visibility:hidden;}


.cdrPop {width:100%;}
.cdrPop [class*="txtDescription"] {display:block; font-size:1.25rem; color:#000; font-weight:400; line-height:1.5;}
.cdrPop .txtDescription {font-size:18px;}
.cdrPop .txtDescription02 {font-size:16px;}
.cdrPop .txtDescription03 {font-size:16px; color:#757575;}

.cdrPop .topGuide {width:100%; padding:0 20px; box-sizing:border-box; line-height:1.5;}
.cdrPop .topGuide > p > span {font-size:16px; color:#000; font-weight:400;}

.cdrPop [class*="icon"] {display:inline-block; background-repeat:no-repeat; background-position:center center; background-size:100% auto;}
.cdrPop .iconCheckBox {width:20px; height:20px; background-color:#fff; /*1background-image:url('/images/icon/input-checkbox-default.svg');*/ border:1px solid #757575; border-radius:4px; }

.cdrPop [class*="btnIcon_"] {display:inline-block; background-repeat:no-repeat; background-position:center center;}
.cdrPop .btnIcon_description {width:34px; height:34px; border:0; background-image:url('/images/icon/ico-guide.svg'); background-size:18px auto; vertical-align:middle;}
.cdrPop .btnIcon_X {width:30px; height:30px; background-image:url('/images/icon/ico-24-delete-grey.svg'); background-size:20px auto;}

.cdrPop [class*="btnBlue"] {display:inline-block; border:0; background-color:#3C89F9; border-radius:8px; color:#fff; font-weight:400; line-height:1.5; box-sizing:border-box;}
.cdrPop .btnBlue {padding:12px 29px; height:48px; font-size:16px;}
.cdrPop .btnBlueSmall {padding:5px 23px; height:36px; font-size:12px;}

.cdrPop [class*="btnBlue02"] {background-color:#006BEA}
.cdrPop .btnBlue02Small {padding:6px 23px; height:32px; font-size:12px;}

.cdrPop [class*="btnGray"] {display:inline-block; border:0; background-color:#8F8F8F; border-radius:8px; color:#fff; font-weight:400; line-height:1.5; text-align:center; box-sizing:border-box;}
.cdrPop .btnGray {padding:12px; height:48px; font-size:16px;}

.cdrPop [class*="btnPuple"] {display:inline-block; border:0; background-color:#647CFA; border-radius:8px; color:#fff; font-weight:400; line-height:1.5; box-sizing:border-box;}
.cdrPop .btnPuple {height:44px; font-size:16px;}

.cdrPop [class*="btnSky"] {display:inline-block; border:0; background-color:#647CFA; border-radius:8px; color:#fff; font-weight:400; line-height:1.5; box-sizing:border-box;}

.cdrPop .btnSky {height:44px; font-size:16px;}

.selectBox {position:relative; width:240px; border:1px solid #eee; background-color:#fff;  border-radius:8px;}
.selectBox .icon_arrow {display:block; position:absolute; right:10px; top:50%; width:16px; height:16px; background:url(/images/icon/ico-select-arrow.svg) no-repeat center center; background-size:100% auto; transform:translateY(-50%);}
.selectBox select {position:relative; z-index:1; padding:0 40px 0 12px; width:100%; height:48px; border:0; background-color:transparent; border-radius:8px; font-size:16px; color:#000; font-weight:500; appearance:none; -webkit-appearance:none; box-sizing:border-box;}
.selectBox select::-ms-expand {display:none;}
.selectBox.on .icon_arrow {transform:translateY(-50%) rotate(180deg);}

.checkBox {display:inline-block; position:relative; padding-left:24px;}
.checkBox input[type="checkbox"] {position:absolute; left:0; top:0; z-index:1; width:100%; height:100%; opacity:0;}
.checkBox .iconCheckBox {position:absolute; left:0; top:50%; transform:translateY(-50%); width:20px; height:20px; border:1px solid #757575; border-radius:4px;}
.checkBox input[type="checkbox"]:checked + .iconCheckBox {background-image:url('/images/icon/input-checkbox-abled.svg');}
.checkBox .checkLabel {display:block; font-size:16px; color:#000; font-weight:400;}

.cdrStepCont {/*padding:0 40px;*/ width:100%; box-sizing:border-box;}

.cdrGuideMsg {text-align:center;}

.myCdrSelect {display:flex; align-items:center; flex-flow:wrap; flex-direction:column; gap:30px; margin:40px 0; padding:32px 40px; background-color:rgba(60, 137, 249, 0.05); border-radius:8px;}
.myCdrSelect .txtDescription03 {text-align:center;}
.myCdrForm {display:flex; align-items:end; justify-content:end; gap:10px;}
.myCdrForm > legend {position:absolute; left:-9999em; top:-9999em; font-size:10px; visibility:hidden;}
.mcSlelectList {display:flex; gap:30px; margin:0 auto; }
.mcSlelectList > li {display:flex; flex-direction:column; align-items:start; justify-content:center; gap:10px; letter-spacing:0;}
.mcSlelectList > li .titMscs {display:inline-block; margin-right:10px; font-size:16px; color:#000; font-weight:500; line-height:1.5;}
.mcSlelectList > li .titMscsBtn {display:inline-block; position:relative; padding-right:34px; font-size:16px; color:#000; font-weight:500;  line-height:1.5;}
.mcSlelectList > li .titMscsBtn .btnIcon_description {position:absolute; right:0; top:50%; transform:translateY(-50%); }

.cdrsTabWrap {display:flex; align-items:center;}
.cdrsTab {display:inline-flex; align-items:center; gap:24px; margin:0 auto; width:100%; max-width:384px;}
.cdrsTab > li {display:inline-block; width:33.33%;}
.cdrsTab > li .btnTab {display:block; position:relative; padding:0 14px 16px; min-width:112px; width:100%; height:40px; font-size:18px; color:#bababa; font-weight:500;}
.cdrsTab > li .btnTab.active {color:#000; border-bottom:2px solid #000;}
.cdrsTab > li .btnTab.active:after {content:''; position:absolute; left:0; bottom:0; width:100%; height:2px; background-color:#000;}

.cdrsTabContWrap {margin:24px 0;}
.cdrsTabCont {display:none;}
.cdrsTabCont.show {display:block;}

.studyInfoTable {margin-top:20px; width:100%;}
.studyInfoTable .txtSti {font-size:16px; color:#000; font-weight:500; line-height:1.5;}
.studyInfoTable .txtSti_s {font-size:14px; color:#000; font-weight:500; line-height:1.5;}
.studyInfoTable .txtSti_s.dot {display:block; position:relative; padding-left:10px; }
.studyInfoTable .txtSti_s.dot:before {content:''; position:absolute; left:0; top:8px; width:4px; height:4px; background-color:#000; border-radius:50%;}
.studyInfoTable .linkSti {margin-right:4px; font-size:16px; color:#000; font-weight:500; vertical-align:middle;}
.studyInfoTable .linkSti:hover {text-decoration:underline;}
.studyInfoTable [class*="txtLabel"] {display:inline-block; padding:2px 8px 3px; font-size:10px; color:#fff; font-weight:700; border-radius:4px; vertical-align:middle;}
.studyInfoTable .txtLabelBlue {background-color:#3C89F9}
.studyInfoTable .txtLabelGreen {background-color:#00D4AD}
.studyInfoTable .studyList {display:flex; flex-direction:column; gap:4px;}
.studyInfoTable .studyList > li {text-align:left;}
.studyInfoTable .btnBlue02Small {margin-top:10px;}
.studyInfoTable .myCdrLayer {display:none; position:absolute; left:50%; top:0; padding:5px 10px; background-color:#FEE547; border-radius:4px; transform:translate(-50%, -50%); line-height:1.1;}
.studyInfoTable .myCdrLayer:after {content:''; position:absolute; left:50%; bottom:-5px; width:20px; height:20px; background-color:#FEE547; transform:translateX(-50%) rotate(45deg)}
.studyInfoTable .myCdrLayer .txtSti_s {position:relative; z-index:1; line-height:1.1;}
.studyInfoTable .col01 {width:15%}
.studyInfoTable .col02 {width:28.33%}
.studyInfoTable table {width:100%; border-collapse: collapse; table-layout:fixed;}
.studyInfoTable table thead tr th {position:relative; padding:18px; background-color:#f7f7f7; text-align:center;}
.studyInfoTable table thead tr th.myCdr .myCdrLayer {display:block;}
.studyInfoTable table tbody tr th {padding:16px 12px; text-align:center; border-width:0 0 1px 1px; border-style:solid; border-color:#eee;}
.studyInfoTable table tbody tr th:nth-of-type(1) {border-width:0 0 1px 0;}
.studyInfoTable table tbody tr td {padding:16px 12px; border-width:0 0 1px 1px; border-style:solid; border-color:#eee;}
.studyInfoTable table tbody tr td.myCdr {background-color:rgba(60, 137, 249, 0.05)}

.cdrsGuideWrap {display:flex; margin-bottom:40px;}
.cdrsGuideWrap .txtGuide {display:inline; margin-right:20px; font-size:16px; color:#757575;}

.bottomBtnWrap {display:flex; gap:10px; align-items:center; justify-content:center; padding:20px 0 40px; width:100%;}
.bottomBtnWrap .btnBlue {margin:0 auto; width:100%; max-width:400px;}
.bottomBtnWrap .btnGray {margin:0 auto; width:100%; max-width:400px;}

.skipBtnWrap {display:flex; align-items:center; margin-top:30px;}
.skipBtnWrap .btnSkip {display:inline-block; margin:0 auto;}
.skipBtnWrap .btnSkip .txtBtnLabel {display:block; font-size:14px; color:#000; font-weight:500; line-height:1.5;}

.myCdrWrap {display:flex; gap:20px;}
.myCdrTabWrap {display:flex; width:calc(50% - 10px);}
.myCdrTab {display:flex; flex-direction:column; gap:20px; width:100%;}
.myCdrTab > li {width:100%;}
.btnCdrTab {display:flex; align-items:center; gap:10px; padding:20px; width:100%; border:1px solid #eee; border-radius:4px;}
.btnCdrTab:hover,
.btnCdrTab.active {border-color:#3C89F9; background-color:#ECF0F5;}
.btnCdrTab .txtBtnLabel {font-size:16px; color:#000; font-weight:500;}
.btnCdrTab [class*="iconCdr"] {display:block; width:44px; height:44px; background-repeat:no-repeat; background-position:center center;}
.btnCdrTab .iconCdr01 {background-image:url('/images/icon/cdr/ico_cdr01.svg'); background-size:40px auto;}
.btnCdrTab .iconCdr08 {background-image:url('/images/icon/cdr/ico_cdr08.svg'); background-size:40px auto;}
.btnCdrTab .iconCdr20 {background-image:url('/images/icon/cdr/ico_cdr20.svg'); background-size:40px auto;}
.btnCdrTab .iconCdr {display:flex; align-items:center; justify-content:center;}
.btnCdrTab .iconCdr > img {width:40px; height:40px;}

.myCdrTabContWrap {width:calc(50% - 10px);}
.myCdrScrollWrap {overflow-x:hidden; overflow-y:auto; display:block; width:100%; height:100%; max-height:298px; border:1px solid #eee; border-radius:4px;}
.myCdrList {padding:0 20px;}
.myCdrList > li {position:relative; padding:0 10px; border-top:1px solid #eee;}
.myCdrList > li:nth-of-type(1) {border-top:0;}
.myCdrList > li .checkBox {display:block; z-index:1; padding:20px 24px 20px 0;}
.myCdrList > li .checkBox .iconCheckBox {left:auto; right:0;}

.myCdrList > li .cdrDeskLayer {display:none; position:absolute; left:0; bottom:calc(50% + 30px); padding:5px 10px; max-width:70%; background-color:#eee; border-radius:8px; line-height:0;}
.myCdrList > li .cdrDeskLayer:after {content:''; position:absolute; left:10%; bottom:-10px; width:20px; height:20px; background-color:#eee; transform:rotate(45deg)}
.myCdrList > li .cdrDeskLayer .txtDesk {position:relative; z-index:1; font-size:12px; color:#000; font-weight:500; line-height:1.3;}
.myCdrList > li:hover .cdrDeskLayer {display:block; z-index:2;}


.myCdrWrap + .bottomBtnWrap {margin-top:40px;}
.myCdrWrap + .bottomBtnWrap .btnBlue {max-width:300px;}

.myCdrBox {display:flex; flex-direction:column; gap:10px; margin-top:40px; padding:20px; background-color:rgba(60, 137, 249, 0.05); border-radius:4px;}
.myCdrBox .titMyCdr {font-size:16px; color:#000; font-weight:500;}
.myCdrBox .mcList {display:flex; flex-flow:wrap; gap:10px;}
.myCdrBox .mcList .cdrLabel {display:inline-flex; align-items:center; padding:3px 0 3px 10px; border:1px solid #eee; background-color:#fff; border-radius:50px; line-height:1.5;}
.myCdrBox .mcList .cdrLabel .txtLabel {font-size:14px; color:#000;}

.myCdrBox + .bottomBtnWrap {margin-top:40px;}
.myCdrBox + .bottomBtnWrap [class*="btn"] {margin:0; max-width:200px;}

/* 기관장 및 경영진 과정 목록 */
.classListWrap {display:flex; align-items:center; justify-content:center; row-gap:16px; margin:0 -8px; }
.classListWrap > li {padding:0 8px; width:100%; box-sizing:border-box;}
.classListWrap [class*="classLink"] {display:flex; align-items:center; justify-content:center; flex-direction:column; gap:24px; overflow:hidden; position:relative; top:0; right:0; bottom:0; left:0; padding:24px 20px 32px; border:1px solid #eee; border-radius:8px; box-sizing:border-box;}
.classListWrap [class*="classLink"] .titleWrap {display:flex; justify-content:center; flex-direction:column; gap:16px; text-align:center; }
.classListWrap [class*="classLink"] .title {display:-webkit-box; width:100%; font-size:16px; color:#111; font-weight:500; text-align:center; text-overflow:ellipsis; white-space:nowrap;}
.classListWrap [class*="classLink"] .titleWrap:before {content:''; margin:0 auto; width:112px; height:112px; background-repeat:no-repeat; background-position:center center;}
.classListWrap [class*="classLink"] .btnClassView {display:inline-block; padding:10px; width:160px; height:44px; border:0; border-radius:8px; color:#fff; font-weight:400; line-height:1.5; text-align:center; box-sizing:border-box;}

.classListWrap .classLink_purple {cursor: pointer}
.classListWrap .classLink_purple:hover,
.classListWrap .classLink_purple:focus {border-width:2px; border-color:#647CFA; background-color:#F2F4FF;}
.classListWrap .classLink_purple .titleWrap:before {background-image:url('/images/icon/icon_director01.svg'); background-size:100% auto;}
.classListWrap .classLink_purple .btnClassView {background-color:#647CFA;}

.classListWrap .classLink_sky {cursor: pointer}
.classListWrap .classLink_sky:hover,
.classListWrap .classLink_sky:focus {border-width:2px; border-color:#21B9FF; background-color:#EAF8FF;}
.classListWrap .classLink_sky .titleWrap:before {background-image:url('/images/icon/icon_director02.svg'); background-size:80px auto;}
.classListWrap .classLink_sky .btnClassView {background-color:#21B9FF;}

/* 태블릿 */
@media only screen and (min-width:768px) and (max-width:1023px) {
    .cdrStepCont {padding:40px 0;}

    .myCdrSelect .txtDescription02 {text-align:left;}
    .myCdrForm {align-items:center; flex-direction:column; gap:20px;}
    .mcSlelectList {align-items:start;}
    .mcSlelectList > li {width:100%; justify-content: space-between;}

    .classListWrap {padding-top:16px;}
}

/* 모바일 */
@media only screen and (min-width:280px) and (max-width:767px) {
    .popContentWrap {padding-left:0 !important; padding-right:0 !important;}

    .cdrPop .txtDescription02 {font-size:14px;}

    .cdrPop .topGuide {padding:10px 0; }
    .cdrPop .topGuide > p > span {font-size:14px !important;}

    .cdrStepCont {padding:40px 0;}

    .cdrsTab > li .btnTab {min-width:auto;}
    .cdrsTab > li .btnTab.active:after {height:1px;}

    .cdrsTabContWrap {margin-top:40px;}
    .cdrsTab {gap:10px;}
    .cdrsTab > li .btnTab {padding-left:10px; padding-right:10px; font-size:16px;}


    .studyInfoTable .myCdrLayer {top:-15%;}
    .studyInfoTable .myCdrLayer .txtSti_s {font-size:12px; white-space:nowrap;}
    .studyInfoTable table,
    .studyInfoTable thead,
    .studyInfoTable tbody,
    .studyInfoTable tr,
    .studyInfoTable th,
    .studyInfoTable td {display:block;}
    .studyInfoTable table {margin-top:0;}
    .studyInfoTable table thead tr th:nth-of-type(1) {display:none;}
    .studyInfoTable table thead tr {display:flex;}
    .studyInfoTable table thead tr th {padding:10px; width:33.33%; border-width:1px 0; border-style:solid; border-color:#eee; background-color:transparent;}
    .studyInfoTable table thead tr th.myCdr {background-color:rgba(60, 137, 249, 0.05)}
    .studyInfoTable table tbody tr {margin-top:20px; border:1px solid #eee; border-radius:4px;}
    .studyInfoTable table tbody tr.myCdrBg {background-color:rgba(60, 137, 249, 0.05)}
    .studyInfoTable table tbody tr th,
    .studyInfoTable table tbody tr th:nth-of-type(1),
    .studyInfoTable table tbody tr td {border-width:0 0 1px 0;}
    .studyInfoTable table tbody tr td:last-child {border-width:0;}

    .studyInfoTable table tbody tr th,
    .studyInfoTable table tbody tr td {position:relative; padding-left:25%;}
    .studyInfoTable table tbody tr th:after,
    .studyInfoTable table tbody tr td:after {content:''; position:absolute; left:0; top:0; width:calc(25% - 10px); height:100%; background-color:#f7f7f7;}

    .studyInfoTable table tbody tr th {position:relative; text-align:left;}
    .studyInfoTable table tbody tr th:before {content:'구분'; position:absolute; left:0; top:50%; z-index:1; width:calc(25% - 10px); font-weight:700; text-align:center; transform:translateY(-50%)}
    .studyInfoTable table tbody tr td:before {content:''; position:absolute; left:0; top:50%; z-index:1; width:calc(25% - 10px); font-weight:700; text-align:center; transform:translateY(-50%)}
    .studyInfoTable table tbody tr td:nth-of-type(1):before {content:'신진';}
    .studyInfoTable table tbody tr td:nth-of-type(2):before {content:'중견';}
    .studyInfoTable table tbody tr td:nth-of-type(3):before {content:'리더';}

    .cdrsGuideWrap {justify-content:space-between; flex-flow:wrap;}
    .cdrsGuideWrap .txtGuide {width:100%; font-size:14px;}
    .cdrsGuideWrap .btnBlue02Small {margin-top:10px;}

    .myCdrSelect {padding:20px;}
    .myCdrSelect .txtDescription02 {text-align:left;}
    .myCdrForm {align-items:center; flex-direction:column; gap:20px; width:100%;}
    .mcSlelectList {flex-direction:column; align-items:start; width:100%;}
    .mcSlelectList > li {width:100%;}
    .mcSlelectList > li .selectBox {width:100%;}

    .myCdrWrap {flex-direction:column;}
    .myCdrTabWrap {width:100%;}
    .myCdrTab {flex-direction:row; gap:10px;}
    .btnCdrTab {padding:10px 0; flex-direction:column; align-items:center; justify-content:center;}
    .btnCdrTab .txtBtnLabel {font-size:14px;}

    .myCdrTabContWrap {width:100%;}

    .classListWrap {flex-direction:row; flex-flow:wrap; padding-top:16px;}
    .classLink .title {font-size:14px;}

}